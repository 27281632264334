define("@zestia/ember-simple-infinite-scroller/components/infinite-scroller", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/runloop", "@ember/object", "rsvp", "@ember/service", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _runloop, _object, _rsvp, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{
      concat
      "infinite-scroller"
      (if this.isLoading " infinite-scroller--loading")
      (if this.isScrollable " infinite-scroller--scrollable")
    }}
    ...attributes
    {{did-insert this.handleInsertElement}}
    {{will-destroy this.handleDestroyElement}}
  >
    {{
      yield
      (hash
        error=this.error
        isScrollable=this.isScrollable
        isLoading=this.isLoading
        loadMore=this.loadMore
      )
    }}
  </div>
  */
  {
    "id": "z6kdPdJJ",
    "block": "[[[11,0],[16,0,[28,[37,0],[\"infinite-scroller\",[52,[30,0,[\"isLoading\"]],\" infinite-scroller--loading\"],[52,[30,0,[\"isScrollable\"]],\" infinite-scroller--scrollable\"]],null]],[17,1],[4,[38,2],[[30,0,[\"handleInsertElement\"]]],null],[4,[38,3],[[30,0,[\"handleDestroyElement\"]]],null],[12],[1,\"\\n  \"],[18,2,[[28,[37,5],null,[[\"error\",\"isScrollable\",\"isLoading\",\"loadMore\"],[[30,0,[\"error\"]],[30,0,[\"isScrollable\"]],[30,0,[\"isLoading\"]],[30,0,[\"loadMore\"]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"concat\",\"if\",\"did-insert\",\"will-destroy\",\"yield\",\"hash\"]]",
    "moduleName": "@zestia/ember-simple-infinite-scroller/components/infinite-scroller.hbs",
    "isStrictMode": false
  });
  let InfiniteScrollerComponent = _exports.default = (_dec = (0, _service.inject)('-infinite-scroller'), _class = class InfiniteScrollerComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_infiniteScroller", _descriptor, this);
      _initializerDefineProperty(this, "error", _descriptor2, this);
      _initializerDefineProperty(this, "isLoading", _descriptor3, this);
      _initializerDefineProperty(this, "isScrollable", _descriptor4, this);
      _defineProperty(this, "element", null);
    }
    get selector() {
      return this.args.selector || null;
    }
    get useDocument() {
      return this.args.useDocument || false;
    }
    get scrollDebounce() {
      return this.args.scrollDebounce || 100;
    }
    get leeway() {
      return parseInt(this.args.leeway || '0%', 10);
    }
    handleInsertElement(element) {
      this._registerElement(element);
      this._scheduleCheckScrollable();
      this._listen();
    }
    handleDestroyElement() {
      this._stopListening();
      this._deregisterElement();
    }
    loadMore() {
      this._loadMore();
    }
    _registerElement(element) {
      this.element = element;
    }
    _deregisterElement() {
      this.element = null;
    }
    _isScrollable() {
      let element = this._scroller();
      if (this.useDocument) {
        element = this._documentElement();
      }
      if (!element) {
        return;
      }
      return element.scrollHeight > element.clientHeight;
    }
    _scheduleCheckScrollable() {
      (0, _runloop.scheduleOnce)('afterRender', this, '_checkScrollable');
    }
    _checkScrollable() {
      this.isScrollable = this._isScrollable();
    }
    _listen() {
      this._scrollHandler = this._scroll.bind(this);
      this._listener().addEventListener('scroll', this._scrollHandler);
    }
    _stopListening() {
      this._listener().removeEventListener('scroll', this._scrollHandler);
      (0, _runloop.cancel)(this._scrollDebounceId);
    }
    _scroll(e) {
      this._scrollDebounceId = (0, _runloop.debounce)(this, '_debouncedScroll', e, this.scrollDebounce);
    }
    _debouncedScroll() {
      if (this._shouldLoadMore()) {
        this._loadMore();
      }
    }
    _log() {
      this._infiniteScroller.log(...arguments);
    }
    _document() {
      return this._infiniteScroller.document;
    }
    _documentElement() {
      return this._infiniteScroller.documentElement;
    }
    _listener() {
      if (this.useDocument) {
        return this._document();
      } else {
        return this._scroller();
      }
    }
    _scroller() {
      if (this.selector) {
        return this.element.querySelector(this.selector);
      } else {
        return this.element;
      }
    }
    _shouldLoadMore() {
      let state;
      if (this.useDocument) {
        state = this._detectBottomOfElementInDocument();
      } else {
        state = this._detectBottomOfElement();
      }
      state.shouldLoadMore = state.reachedBottom && !this.isLoading;
      this._log(state);
      return state.shouldLoadMore;
    }
    _detectBottomOfElementInDocument() {
      const scroller = this._scroller();
      const clientHeight = this._infiniteScroller.documentElement.clientHeight;
      const bottom = scroller.getBoundingClientRect().bottom;
      const leeway = this.leeway;
      const pixelsToBottom = bottom - clientHeight;
      const percentageToBottom = pixelsToBottom / bottom * 100;
      const reachedBottom = percentageToBottom <= leeway;
      return {
        clientHeight,
        bottom,
        leeway,
        pixelsToBottom,
        percentageToBottom,
        reachedBottom
      };
    }
    _detectBottomOfElement() {
      const scroller = this._scroller();
      const scrollHeight = scroller.scrollHeight;
      const scrollTop = scroller.scrollTop;
      const clientHeight = scroller.clientHeight;
      const bottom = scrollHeight - clientHeight;
      const leeway = this.leeway;
      const pixelsToBottom = bottom - scrollTop;
      const percentageToBottom = pixelsToBottom / bottom * 100;
      const reachedBottom = percentageToBottom <= leeway;
      return {
        scrollHeight,
        scrollTop,
        clientHeight,
        bottom,
        leeway,
        pixelsToBottom,
        percentageToBottom,
        reachedBottom
      };
    }
    _loadMore() {
      const action = this.args.onLoadMore;
      if (typeof action !== 'function') {
        return;
      }
      this.error = null;
      this.isLoading = true;
      (0, _rsvp.resolve)(action()).catch(this._loadError.bind(this)).finally(this._loadFinished.bind(this));
    }
    _loadError(error) {
      this.error = error;
    }
    _loadFinished() {
      this.isLoading = false;
      this._scheduleCheckScrollable();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_infiniteScroller", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isScrollable", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InfiniteScrollerComponent);
});