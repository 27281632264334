define("@zestia/ember-simple-infinite-scroller/services/-infinite-scroller", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class InfiniteScrollerService extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "isFastBoot", typeof FastBoot !== 'undefined');
      _defineProperty(this, "debug", false);
      _defineProperty(this, "_log", []);
      if (!this.isFastBoot) {
        this.document = document;
        this.documentElement = document.documentElement;
      }
    }
    log(state) {
      if (this.debug) {
        this._log.push(state);

        // eslint-disable-next-line
        console.table([state]);
      }
    }
  }
  _exports.default = InfiniteScrollerService;
});