this.SVG = this.SVG || {}, this.SVG.Filter = function (r) {
  "use strict";

  function e(t) {
    return function (t) {
      if (Array.isArray(t)) {
        for (var n = 0, e = new Array(t.length); n < t.length; n++) e[n] = t[n];
        return e
      }
    }(t) || function (t) {
      if (Symbol.iterator in Object(t) || "[object Arguments]" === Object.prototype.toString.call(t)) return Array.from(t)
    }(t) || function () {
      throw new TypeError("Invalid attempt to spread non-iterable instance")
    }()
  }

  function i(t, n) {
    if (!(t instanceof n)) throw new TypeError("Cannot call a class as a function")
  }

  function o(t, n) {
    for (var e = 0; e < n.length; e++) {
      var r = n[e];
      r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(t, r.key, r)
    }
  }

  function u(t, n, e) {
    return n && o(t.prototype, n), e && o(t, e), t
  }

  function n(t) {
    return (n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
      return typeof t
    } : function (t) {
      return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t
    })(t)
  }

  function a(t) {
    return (a = "function" == typeof Symbol && "symbol" === n(Symbol.iterator) ? function (t) {
      return n(t)
    } : function (t) {
      return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : n(t)
    })(t)
  }

  function c(t, n) {
    return !n || "object" !== a(n) && "function" != typeof n ? function (t) {
      if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return t
    }(t) : n
  }

  function f(t) {
    return (f = Object.setPrototypeOf ? Object.getPrototypeOf : function (t) {
      return t.__proto__ || Object.getPrototypeOf(t)
    })(t)
  }

  function s(t, n, e) {
    return (s = "undefined" != typeof Reflect && Reflect.get ? Reflect.get : function (t, n, e) {
      var r = function (t, n) {
        for (; !Object.prototype.hasOwnProperty.call(t, n) && null !== (t = f(t)););
        return t
      }(t, n);
      if (r) {
        var i = Object.getOwnPropertyDescriptor(r, n);
        return i.get ? i.get.call(e) : i.value
      }
    })(t, n, e || t)
  }

  function l(t, n) {
    return (l = Object.setPrototypeOf || function (t, n) {
      return t.__proto__ = n, t
    })(t, n)
  }

  function h(t, n) {
    if ("function" != typeof n && null !== n) throw new TypeError("Super expression must either be null or a function");
    t.prototype = Object.create(n && n.prototype, {
      constructor: {
        value: t,
        writable: !0,
        configurable: !0
      }
    }), n && l(t, n)
  }

  function t(i) {
    return function () {
      for (var t = arguments.length, n = new Array(t), e = 0; e < t; e++) n[e] = arguments[e];
      for (var r = i.length; r--;) null != n[r] && this.attr(i[r], n[r])
    }
  }
  var p = function () {
      function e(t) {
        var n;
        return i(this, e), (n = c(this, f(e).call(this, r.nodeOrNew("filter", t), t))).$source = "SourceGraphic", n.$sourceAlpha = "SourceAlpha", n.$background = "BackgroundImage", n.$backgroundAlpha = "BackgroundAlpha", n.$fill = "FillPaint", n.$stroke = "StrokePaint", n.$autoSetIn = !0, n
      }
      return h(e, r.Element), u(e, [{
        key: "put",
        value: function (t, n) {
          return !(t = s(f(e.prototype), "put", this).call(this, t, n)).attr("in") && this.$autoSetIn && t.attr("in", this.$source), t.attr("result") || t.attr("result", t.id()), t
        }
      }, {
        key: "remove",
        value: function () {
          return this.targets().each("unfilter"), s(f(e.prototype), "remove", this).call(this)
        }
      }, {
        key: "targets",
        value: function () {
          return r.find('svg [filter*="' + this.id() + '"]')
        }
      }, {
        key: "toString",
        value: function () {
          return "url(#" + this.id() + ")"
        }
      }]), e
    }(),
    g = function () {
      function e(t) {
        var n;
        return i(this, e), (n = c(this, f(e).call(this, t, t))).result(n.id()), n
      }
      return h(e, r.Element), u(e, [{
        key: "in",
        value: function (t) {
          if (null != t) return this.attr("in", t);
          var n = this.attr("in");
          return this.parent() && this.parent().find('[result="'.concat(n, '"]'))[0] || n
        }
      }, {
        key: "result",
        value: function (t) {
          return this.attr("result", t)
        }
      }, {
        key: "toString",
        value: function () {
          return this.result()
        }
      }]), e
    }(),
    d = {
      blend: t(["in", "in2", "mode"]),
      colorMatrix: t(["type", "values"]),
      composite: t(["in", "in2", "operator"]),
      convolveMatrix: function (t) {
        t = new r.Array(t).toString(), this.attr({
          order: Math.sqrt(t.split(" ").length),
          kernelMatrix: t
        })
      },
      diffuseLighting: t(["surfaceScale", "lightingColor", "diffuseConstant", "kernelUnitLength"]),
      displacementMap: t(["in", "in2", "scale", "xChannelSelector", "yChannelSelector"]),
      dropShadow: t(["in", "dx", "dy", "stdDeviation"]),
      flood: t(["flood-color", "flood-opacity"]),
      gaussianBlur: function (t, n) {
        var e = 0 < arguments.length && void 0 !== t ? t : 0,
          r = 1 < arguments.length && void 0 !== n ? n : e;
        this.attr("stdDeviation", e + " " + r)
      },
      image: function (t) {
        this.attr("href", t, r.namespaces.xlink)
      },
      morphology: t(["operator", "radius"]),
      offset: t(["dx", "dy"]),
      specularLighting: t(["surfaceScale", "lightingColor", "diffuseConstant", "specularExponent", "kernelUnitLength"]),
      tile: t([]),
      turbulence: t(["baseFrequency", "numOctaves", "seed", "stitchTiles", "type"])
    };
  ["blend", "colorMatrix", "componentTransfer", "composite", "convolveMatrix", "diffuseLighting", "displacementMap", "dropShadow", "flood", "gaussianBlur", "image", "merge", "morphology", "offset", "specularLighting", "tile", "turbulence"].forEach(function (t) {
    var o = r.utils.capitalize(t),
      e = d[t];
    p[o + "Effect"] = function () {
      function n(t) {
        return i(this, n), c(this, f(n).call(this, r.nodeOrNew("fe" + o, t), t))
      }
      return h(n, g), u(n, [{
        key: "update",
        value: function (t) {
          return e.apply(this, t), this
        }
      }]), n
    }(), p.prototype[t] = r.wrapWithAttrCheck(function (t) {
      var n = new p[o + "Effect"];
      if (null == t) return this.put(n);
      for (var e = arguments.length, r = new Array(1 < e ? e - 1 : 0), i = 1; i < e; i++) r[i - 1] = arguments[i];
      return "function" == typeof t ? t.call(n, n) : r.unshift(t), this.put(n).update(r)
    })
  }), r.extend(p, {
    merge: function (t) {
      var n = this.put(new p.MergeEffect);
      return "function" == typeof t ? t.call(n, n) : (t instanceof Array ? t : Array.prototype.slice.call(arguments)).forEach(function (t) {
        t instanceof p.MergeNode ? n.put(t) : n.mergeNode(t)
      }), n
    },
    componentTransfer: function (t) {
      var n = 0 < arguments.length && void 0 !== t ? t : {},
        e = this.put(new p.ComponentTransferEffect);
      if ("function" == typeof n) return n.call(e, e), e;
      if (!(n.r || n.g || n.b || n.a)) {
        n = {
          r: n,
          g: n,
          b: n,
          a: n
        }
      }
      for (var r in n) e.add(new(p["Func" + r.toUpperCase()])(n[r]));
      return e
    }
  });
  ["distantLight", "pointLight", "spotLight", "mergeNode", "FuncR", "FuncG", "FuncB", "FuncA"].forEach(function (t) {
    var e = r.utils.capitalize(t);
    p[e] = function () {
      function n(t) {
        return i(this, n), c(this, f(n).call(this, r.nodeOrNew("fe" + e, t), t))
      }
      return h(n, g), n
    }()
  });
  ["funcR", "funcG", "funcB", "funcA"].forEach(function (t) {
    var n = p[r.utils.capitalize(t)],
      e = r.wrapWithAttrCheck(function () {
        return this.put(new n)
      });
    p.ComponentTransferEffect.prototype[t] = e
  });
  ["distantLight", "pointLight", "spotLight"].forEach(function (t) {
    var n = p[r.utils.capitalize(t)],
      e = r.wrapWithAttrCheck(function () {
        return this.put(new n)
      });
    p.DiffuseLightingEffect.prototype[t] = e, p.SpecularLightingEffect.prototype[t] = e
  }), r.extend(p.MergeEffect, {
    mergeNode: function (t) {
      return this.put(new p.MergeNode).attr("in", t)
    }
  }), r.extend(r.Defs, {
    filter: function (t) {
      var n = this.put(new p);
      return "function" == typeof t && t.call(n, n), n
    }
  }), r.extend(r.Container, {
    filter: function (t) {
      return this.defs().filter(t)
    }
  }), r.extend(r.Element, {
    filterWith: function (t) {
      var n = t instanceof p ? t : this.defs().filter(t);
      return this.attr("filter", n)
    },
    unfilter: function () {
      return this.attr("filter", null)
    },
    filterer: function () {
      return this.reference("filter")
    }
  });
  var y = {
    blend: function (t, n) {
      return this.parent() && this.parent().blend(this, t, n)
    },
    colorMatrix: function (t, n) {
      return this.parent() && this.parent().colorMatrix(t, n).in(this)
    },
    componentTransfer: function (t) {
      return this.parent() && this.parent().componentTransfer(t).in(this)
    },
    composite: function (t, n) {
      return this.parent() && this.parent().composite(this, t, n)
    },
    convolveMatrix: function (t) {
      return this.parent() && this.parent().convolveMatrix(t).in(this)
    },
    diffuseLighting: function (t, n, e, r) {
      return this.parent() && this.parent().diffuseLighting(t, e, r).in(this)
    },
    displacementMap: function (t, n, e, r) {
      return this.parent() && this.parent().displacementMap(this, t, n, e, r)
    },
    dropShadow: function (t, n, e) {
      return this.parent() && this.parent().dropShadow(this, t, n, e).in(this)
    },
    flood: function (t, n) {
      return this.parent() && this.parent().flood(t, n)
    },
    gaussianBlur: function (t, n) {
      return this.parent() && this.parent().gaussianBlur(t, n).in(this)
    },
    image: function (t) {
      return this.parent() && this.parent().image(t)
    },
    merge: function (t) {
      var n;
      return t = t instanceof Array ? t : e(t), this.parent() && (n = this.parent()).merge.apply(n, [this].concat(e(t)))
    },
    morphology: function (t, n) {
      return this.parent() && this.parent().morphology(t, n).in(this)
    },
    offset: function (t, n) {
      return this.parent() && this.parent().offset(t, n).in(this)
    },
    specularLighting: function (t, n, e, r, i) {
      return this.parent() && this.parent().specularLighting(t, e, r, i).in(this)
    },
    tile: function () {
      return this.parent() && this.parent().tile().in(this)
    },
    turbulence: function (t, n, e, r, i) {
      return this.parent() && this.parent().turbulence(t, n, e, r, i).in(this)
    }
  };
  return r.extend(g, y), r.extend(p.MergeEffect, {
    in: function (t) {
      return t instanceof p.MergeNode ? this.add(t, 0) : this.add((new p.MergeNode).in(t), 0), this
    }
  }), r.extend([p.CompositeEffect, p.BlendEffect, p.DisplacementMapEffect], {
    in2: function (t) {
      if (null != t) return this.attr("in2", t);
      var n = this.attr("in2");
      return this.parent() && this.parent().find('[result="'.concat(n, '"]'))[0] || n
    }
  }), p.filter = {
    sepiatone: [.343, .669, .119, 0, 0, .249, .626, .13, 0, 0, .172, .334, .111, 0, 0, 0, 0, 0, 1, 0]
  }, p
}(SVG);
