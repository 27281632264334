window.is_turkmen = getCookie('IS_TURKMEN') === 'true';
window.GLOBAL_API = {}

const GEO_RESTRICTION_TYPE = {
  RESTRICTED: 'RESTRICTED',
  LOGIN_ONLY: 'LOGIN_ONLY',
}

let loadPartnerConfigPromise = null;
let theme = window.localStorage.themeMode;

if (theme) {
  document.documentElement.setAttribute("data-theme", theme);
} else {
  document.documentElement.setAttribute("data-theme", 'dark');
}


checkPartner()

function domain_from_url(origin) {
  var result
  var match
  if (match = origin.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im)) {
    result = match[1]
    if (match = result.match(/^[^\.]+\.(.+\..+)$/)) {
      result = match[1]
    }
  }
  return result
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function checkPartner() {
  if (window.has_seo) {
    loadPartnerConfigPromise = Promise.resolve();
    checkPartnerMirror();
    sendRequestIpinfo();
  } else {
    loadPartnerConfigPromise = jQuery.ajax({
      type: "GET",
      url: `/casinoapi/api/partner-config`,
      success: (data) => {
        window.partnerConfigs = data;
        window.GLOBAL_API = data.global_api;

        checkPartnerMirror();
        setStyles();
        setCodeInjections();
        sendRequestIpinfo();
        setPWA();
        setSeo();
        setZohoChat();
      },
      cache: true,
    });
  }

  function sendRequestIpinfo() {
    $.get("/siteapi/country", function (response) {
      window.ip = response.ip;
      window.country_code = response.country;
      checkGeoIpRestrictions(response.country);

      // mixpanel
      window.mixpanel.track('User IP website viewed', {
        'User IP': window.ip,
      });

    }).catch(e => {
      window.country_code = "GB";
      checkGeoIpRestrictions(window.country_code);
    });

    if(!window.country_code) {
      window.country_code = 'GB';
    }
  }

  function checkGeoIpRestrictions(countryCode) {
    if (isSiteRestricted(countryCode)) {
      window.partnerConfigs.forbidden = true;
      return;
    }

    if (isRegistrationRestricted(countryCode)) {
      window.partnerConfigs.configs.sign_up = false;
    }
  }

  function isSiteRestricted(userCountryCode) {
    const restrictedCountries = window.partnerConfigs.geoip_restricted_countries.filter(c => c.restriction_type === GEO_RESTRICTION_TYPE.RESTRICTED).map(c => c.country_code);
    return restrictedCountries.includes(userCountryCode);
  }

  function isRegistrationRestricted(userCountryCode) {
    const restrictedCountries = window.partnerConfigs.geoip_restricted_countries.filter(c => c.restriction_type === GEO_RESTRICTION_TYPE.LOGIN_ONLY).map(c => c.country_code);
    return restrictedCountries.includes(userCountryCode);
  }

  function checkPartnerMirror() {
    let mirrors = this.partnerConfigs.mirrors;
    if (mirrors && mirrors.length) {
      let mirror = mirrors.find(k => k.country_code === window.country_code);
      if (mirror) {
        window.location.replace(mirror.redirect_url);
      }
    }
  }

  function setCodeInjections() {
    let code_injection = window.partnerConfigs.code_injection?.find(v => v.type === (window.deviceMobile ? 'mobile' : 'desktop'));
    if (code_injection) {
      $('head').prepend($(code_injection.before_head))
      $('head').append($(code_injection.after_head))
      $('body').prepend($(code_injection.before_body))
      $('body').append($(code_injection.after_body))
    }
  }

  function setSeo() {
    window.partnerConfigs.seo = window.partnerConfigs.seos?.find(v => v.path === '/*' && v.language_id === 'en');
    if (window.partnerConfigs.seo) {
      $('head').append(`<meta name="description" content="${this.partnerConfigs.seo.description}">`);
      $('meta[name=keywords]').attr('content', this.partnerConfigs.seo.keywords);
      document.title = this.partnerConfigs.seo.title;
    }
  }

  function setZohoChat() {
    if (window.partnerConfigs.zoho_chat) {
      $('body').prepend($(window.partnerConfigs.zoho_chat));
    }
  }

  function setPWA() {
    if (this.partnerConfigs.configs.pwa_active) {

      let url = this.partnerConfigs.configs.pwa_manifest_url.replace('https://betproduction-file-managers.s3.eu-central-1.amazonaws.com', window.GLOBAL_API.media)
      $('head').append(`<link rel="manifest" href="${url}">`);
    }
  }

  function setStyles() {
    let partnerConfigs = window.partnerConfigs;

    if (partnerConfigs) {
      let partnerStyles = partnerConfigs.partner_styles;

      if (partnerStyles) {
        let styles_dark = JSON.parse(partnerStyles.styles_dark);
        let styles_light = JSON.parse(partnerStyles.styles_light);

        let element = document.querySelector('html');
        setStyles();

        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.type == "attributes" && mutation.attributeName === 'data-theme') {
              setStyles();
            }
          });
        });

        observer.observe(element, {
          attributes: true
        });

        function setStyles() {
          switch (element.dataset.theme) {
            case 'dark':
              if (partnerStyles.loading_dark) {
                $('.loading-wrapper .loading span').css('background-image', `url(${partnerStyles.loading_dark.replace('https://betproduction-file-managers.s3.eu-central-1.amazonaws.com', window.GLOBAL_API.media)})`);
              }

              styles_dark.forEach(item => {
                element.style.setProperty(item.key, item.color)
              })
              break;
            case 'light':
              if (partnerStyles.loading_dark) {
                $('.loading-wrapper .loading span').css('background-image', `url(${partnerStyles.loading_light.replace('https://betproduction-file-managers.s3.eu-central-1.amazonaws.com', window.GLOBAL_API.media)})`);
              }

              styles_light.forEach(item => {
                document.documentElement.style.setProperty(item.key, item.color)
              })
              break;
          }
        }
      }
    }
  }
}
