define("sb-mobile/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.default)(function () {
    // Define your engine's route map here
    this.route('sports', function () {
      this.route('line', function () {
        this.route('single', {
          path: '/:sport_id'
        }, function () {
          this.route('region', {
            path: '/:region_ids'
          }, function () {
            this.route('league', {
              path: '/:league_ids'
            }, function () {
              this.route('game', {
                path: '/:game_id'
              });
            });
          });
        });
        this.route('search');
      });
      this.route('live', function () {
        this.route('overview', function () {
          this.route('single', {
            path: '/:sport_id'
          });
        });
        this.route('event-view', {
          path: 'event-view/:sport_id/:league_id/:game_id'
        });
        // this.route("multi-view",  function () {
        //   this.route("show", {path: "/:game_ids"})
        // });
        // this.route("calendar");
        this.route('results');
      });
      this.route('favorites');
      this.route('my-bets');
    });
    this.route('e-sports', function () {
      this.route('overview', function () {
        this.route('single', {
          path: '/:sport_id'
        });
      });
      this.route('event-view', {
        path: 'event-view/:sport_id/:league_id/:game_id'
      });
      this.route('results');
    });
    this.route('promotions', function () {
      this.route('show', {
        path: '/:promotion_id'
      });
    });
    this.route('statistics', {
      path: 'statistics/:sport_id/:region_id/:league_id/:game_id/:is_live'
    });
    this.route('profile', function () {
      this.route('profile-navigation', {
        path: '/'
      });
      this.route('transactions');
      this.route('deposit');
      this.route('withdraw');
      this.route('buy-crypto');
      this.route('bonuses');
      this.route('bet-history');
      this.route('casino-bet-history');
      this.route('messages');
      this.route('my-profile');
      this.route('withdraw-requests');
      this.route('withdraw-via-card-request');
    });
    this.route('confirm');
    this.route('reset-confirm');
    this.route('tournaments', function () {
      this.route('tournament-by-name', {
        path: '/:tournament_name'
      });
      this.route('tournament-by-id', {
        path: '/id/:tournament_uuid'
      });
    });
    this.route('casino', function () {
      this.route('home');
      this.route('single', {
        path: 'play/:path'
      });
      this.route('play', {
        path: ':type/:game_id'
      });
      this.route('category', {
        path: '/:category_url'
      });
    });
    this.route('testcupon');
    this.route('fortuna-wheel');
    this.route('sign-up');
    this.route('sign-in');
    this.route('home');
    this.route('static-pages', {
      path: '/:page_url'
    });
    this.route('unsubscribe');
  });
});